<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd"/>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 대상품명 -->
          <c-select
            type="search"
            codeGroupCd="HHM_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="machineryClassification"
            label="대상품명"
            v-model="searchParam.machineryClassification"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 관리번호(기계번호) -->
          <c-text
            name="itemNo"
            label="관리번호(기계번호)"
            v-model="searchParam.itemNo"
          ></c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 형식번호(구간번호) -->
          <c-text
            name="passNumber"
            label="형식번호(구간번호)"
            v-model="searchParam.passNumber"
          ></c-text>
        </div>
      </template>
    </c-search-box>
    <c-card :title="`[${map.mapName}] 도면 정보`" class="cardClassDetailForm hazardequip-card">
      <template slot="card-description">
        <q-badge color="red" text-color="white" label="도면이미지에 마우스 우클릭을 통해 유해위험기계기구 추가하세요." />
      </template>
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn label="LBL0000209" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div :style="`height: ${zoomer.height}px;`" class="zommer-container col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10">
          <panZoom v-if="mapExists" ref="panZoom" selector="#zoomerImage" :options="zoomer.options">
            <q-img
              id="zoomerImage"
              :src="map.mapSrc"
              :style="`width: ${(zoomer.height - 30) * map.ratio}px; height: ${zoomer.height - 30}px;`"
            >
              <q-menu
                touch-position
                context-menu
                @before-show="beforeShow"
              >
                <q-list dense style="min-width: 100px">
                  <q-item clickable v-close-popup @click="onItemClick">
                    <q-item-section avatar class="hazardequip-mark-add">
                      <q-icon name="add" color="red" />
                    </q-item-section>
                    <q-item-section>기계명</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
              <c-map-marker 
                v-for="(mark, idx) in map.marks"
                :key="idx"
                :isImg="true"
                :imgSrc="mark.hazardEquipDownPath ? mark.hazardEquipDownPath : require('@/assets/images/xxx.png')"
                class="mark-hazardEquip"
                :style="`transform: translate(${(mark.locationXcoordinate * zoomer.ratio) - 15}px, ${(mark.locationYcoordinate * zoomer.ratio) - 25}px);z-index: auto; user-select: auto; width: 30px; height: 30px;`"
                :isSelected="true">
                <template v-slot:innerMark>
                  <q-menu 
                    :ref="'markedproxy' + mark.idx" 
                    :content-class="'hazardequip-menu-layer'"
                    anchor="top right"
                    self="top left">
                    <q-card 
                      flat bordered 
                      @click="linkClick(mark)"
                      :class="['hazardequip-menu-card']">
                      <q-item class="hazardequip-card-section hazardequip-card-item">
                        <q-item-section>
                          <q-item-label>
                            <q-badge rounded color="light-green" :label="mark.machineryClassificationName" /> / 
                            <q-badge rounded color="light-blue" :label="mark.itemNo" /> 
                          </q-item-label>
                        </q-item-section>
                        <q-item-section side>
                          <q-icon name="delete" color="red" class="cursor-pointer" @click.stop="removeClick(mark)" />
                        </q-item-section>
                      </q-item>
                      <q-card-section class="hazardequip-card-section cursor-pointer">
                        <q-icon 
                          name="chevron_right" 
                          class="text-indigo" 
                        />
                        {{mark.passNumber}}
                      </q-card-section>
                    </q-card>
                  </q-menu>
                </template>
              </c-map-marker>
              <!-- <q-img
                v-for="(mark, idx) in map.marks"
                :key="idx"
                class="mark-hazardequip"
                :style="`transform: translate(${(mark.locationXcoordinate * zoomer.ratio) - 15}px, ${(mark.locationYcoordinate * zoomer.ratio) - 15 }px);z-index: auto; user-select: auto; width: 30px; height: 30px;`"
                :src="mark.hazardEquipDownPath ? mark.hazardEquipDownPath : require('@/assets/images/xxx.png')"
              >
                <q-menu 
                  :ref="'markedproxy' + mark.idx" 
                  :content-class="'hazardequip-menu-layer'"
                  anchor="top right"
                  self="top left">
                  <q-card 
                    flat bordered 
                    @click="linkClick(mark)"
                    :class="['hazardequip-menu-card']">
                    <q-item class="hazardequip-card-section hazardequip-card-item">
                      <q-item-section>
                        <q-item-label>
                          <q-badge rounded color="light-green" :label="mark.machineryClassificationName" /> / 
                          <q-badge rounded color="light-blue" :label="mark.itemNo" /> 
                        </q-item-label>
                      </q-item-section>
                      <q-item-section side>
                        <q-icon name="delete" color="red" class="cursor-pointer" @click.stop="removeClick(mark)" />
                      </q-item-section>
                    </q-item>
                    <q-card-section class="hazardequip-card-section cursor-pointer">
                      <q-icon 
                        name="chevron_right" 
                        class="text-indigo" 
                      />
                      {{mark.machineryName}}
                    </q-card-section>
                  </q-card>
                </q-menu>
              </q-img> -->
            </q-img>
          </panZoom>
        </div>
        <div :style="`height: ${zoomer.height}px; overflow-y: auto;`" class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 hazardequip-map-list">
          <q-list bordered padding>
            <template v-for="(mapInfo, idx) in zoomer.maps">
              <q-item :key="idx" clickable @click="mapSelect(mapInfo)">
                <q-item-section class="q-ml-none">
                  <q-img
                    id="zoomerImage"
                    :src="mapInfo.mapSrc"
                    :style="`width: 150px; height: 150px;`"
                  >
                    <div class="absolute-bottom text-subtitle1 text-center">
                      {{mapInfo.mapName}}
                    </div>
                  </q-img>
                </q-item-section>
              </q-item>
              <q-separator v-if="(idx + 1) < zoomer.maps.length" :key="idx + 'sep'" spaced></q-separator>
            </template>
          </q-list>
        </div>
      </template>
    </c-card>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'hazard-equipment-layout',
  props: {
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        machineryClassification: null,
        itemNo: '',
        passNumber: '',
      },
      editable: true,
      detailUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      options: {
        minZoom: 0.5,
        maxZoom: 3,
        initialZoom: 1,
        initialX: 1800,
        initialY: 200,
        bounds: true,
        boundsPadding: 0.1,
        zoomDoubleClickSpeed: 1, 
        onTouch: function() {
          return false;
        }
      },
      zoomer: {
        height: 600,
        ratio: 1,
        style: 'width: 100%; height: 100%;',
        imageStyle: 'width: 90%; height: 90%;',
        value: 100,
        interval: 10,
        options: {
          minZoom: 0.5,
          maxZoom: 3,
          initialZoom: 1,
          initialX: 800,
          initialY: 200,
          bounds: true,
          boundsPadding: 0.1,
          zoomDoubleClickSpeed: 1, 
          onTouch: function(e) {
            return false;
          }
        },
        menu: {
          offsetX: 0,
          offsetY: 0,
        },
        currentMap: {
          sopMapId: ''
        },
        maps: [],
      },
      data: {
        slide: 0,
        mapSrc: require('@/assets/images/no-image.png'),
        fullscreen: false,
        maps: [],
      },
      tempMapDetails: [],
      listUrl: '',
      insertUrl: '',
      isSave: false,
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    window.addEventListener('resize', this.setSize);
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
  computed: {
    map() {
      let map = {
        sopMapId: '',
        mapSrc: '',
        mapName: '',
        ratio: 0,
        marks: []
      }
      if (this.mapExists) {
        map = this.$_.find(this.zoomer.maps, { sopMapId: this.zoomer.currentMap.sopMapId })
      }
      return map;
    },
    mapExists() {
      return this.zoomer.maps && this.zoomer.maps.length > 0 && Boolean(this.zoomer.currentMap.sopMapId)
    }
  },
  watch: {
    supWorks: {
      handler: function () {
        this.setItems();
      },
      deep: true,
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.hhm.hazard.hazardMap.list.url;
      this.insertUrl = transactionConfig.sop.hhm.hazard.hazardMap.insert.url;
      this.deleteUrl = transactionConfig.sop.hhm.hazard.hazardMap.delete.url;
      this.getList();
      this.setSize();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.$set(this.zoomer, 'maps', _result.data)
        if (this.zoomer.maps && this.zoomer.maps.length > 0 && !this.zoomer.currentMap.sopMapId) {
          this.$set(this.zoomer.currentMap, 'sopMapId', this.zoomer.maps[0].sopMapId)
        }
      },);
    },
    setSize() {
      this.zoomer.height = window.innerHeight - 200;
      this.zoomer.ratio = (this.zoomer.height - 30) / 575;
      // this.zoomer.style = `width: 100%; height: ${height}px;`
      // this.zoomer.imageStyle = `width: 90%; height: ${height - 30}px;`
    },
    setStyle(mark) {
      // 너비, 높이가 30px임으로 중간을 맞추기 위해 15px만금 조정
      let style = `transform: translate(${mark.locationXcoordinate - 15}px, ${mark.locationYcoordinate - 15}px);`
        + `z-index: auto; user-select: auto; width: 30px; height: 30px;`
      return style;
    },
    beforeShow(event) {
      let ratio = 575 / (this.zoomer.height - 30)
      this.$set(this.zoomer.menu, 'offsetX', event.offsetX * ratio)
      this.$set(this.zoomer.menu, 'offsetY', event.offsetY * ratio)
    },
    mapSelect(mapInfo) {
      this.$set(this.zoomer.currentMap, 'sopMapId', mapInfo.sopMapId)
    },
    onItemClick() {
      this.popupOptions.title = '유해위험기계기구 검색'; // 유해위험기계기구 검색
      this.popupOptions.param = {
        type: 'single',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/mim/hazardEquipment/hazardEquipmentClassPop.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipPopup;
    },
    closeEquipPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        
        let index = -1;
        this.$_.forEach(this.zoomer.maps, _item => {
          if (index === -1) {
            index = this.$_.findIndex(_item.marks, { hhmHazardousMachineryId: data[0].hhmHazardousMachineryId })
          }
        })

        if (index === -1) {
          let item = data[0]
          this.$http.url = this.insertUrl;
          this.$http.type = 'POST';
          this.$http.param = {
            hhmMapResultId: '',  // 작업위치 번호
            sopMapId: this.map.sopMapId,  // 지도 일련번호
            locationXcoordinate: this.zoomer.menu.offsetX,  // 작업위치 X좌표
            locationYcoordinate: this.zoomer.menu.offsetY,  // 작업위치 Y좌표
            hhmHazardousMachineryId: item.hhmHazardousMachineryId,  // 유해위험기계기구 일련번호
            regUserId: this.$store.getters.user.userId,  // 등록자 ID
          };
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.getList();
          },);
        } else {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: '이미 배치된 기계입니다.', // 이미 배치된 기계입니다.
            type: 'warning', // success / info / warning / error
          });
        }
      }
    },
    linkClick(row) {
      this.popupOptions.target = () =>
      import(`${"@/pages/sop/mim/hazardEquipment/hazardEquipmentClassDetail.vue"}`);
      this.popupOptions.isFull = true;
      this.popupOptions.title = '유해위험기계기구 상세'; // 유해위험기계기구 상세
      this.popupOptions.param = {
        hhmHazardousMachineryId: row.hhmHazardousMachineryId ? row.hhmHazardousMachineryId : '',
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    removeClick(mark) {
      this.$http.url = this.$format(this.deleteUrl, mark.hhmHazardousMachineryId);
      this.$http.type = 'DELETE';
      this.$http.request(() => {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
        this.getList();
      },);
    },
    
  }
};
</script>
<style lang="sass">
.custom-zoomer
  border: solid 1px silver
  background-color: white !important
.mark-hazardequip
  background: unset !important
  cursor: pointer
    
.hazardequip-menu-layer
  border-radius: 10px !important
  max-width: 600px !important
.hazardequip-menu-card
  padding-top: 5px
  padding-bottom: 5px
  margin: 6px
  .hazardequip-card-section
    padding: 0 10px 0 10px
  .hazardequip-card-item
    min-height: auto

.zommer-container
  .vue-pan-zoom-scene
    overflow: auto

.hazardequip-card
  .customCardbody
    margin: 0 !important
    padding: 0 !important

.hazardequip-map-list
  padding-right: 0 !important
  .q-item
    flex-direction: column
    align-items: center

.hazardequip-mark-add
  min-width: 0 !important
  padding: 0 !important

.mark-hazardEquip
  background: unset !important
  cursor: pointer
</style>